import { Card } from '@blueprintjs/core';
import { useGetItemQuery } from '../../store/docApi';
import Picture from '../Widgets/Picture';
import Links from '../Widgets/Links';
import Palette from '../Parts/Palette';
import Sizing from '../Widgets/Sizing';
import ItemColors from '../Widgets/ItemColors';
import FileUpload from '../Widgets/FileUpload';

export default function Overview({ patternPk, patternId, lang }) {
  const { data: item } = useGetItemQuery({ container: "design", label: "pattern", pk: patternPk, id: patternId });
  // const { data: subitems } = useGetRelatedQuery({ name: "isPartOf", value: `pattern/${patternPk}/${patternId}`, container: "design", pk: patternPk })

  // let palette = subitems ? subitems.find(s => s.label === "palette") : undefined;

  function LANG(prop) {
    return item[`${prop}_${lang}`] ? item[`${prop}_${lang}`] : item[prop];
  }

  return item && (
    <Card style={styles.section}>
      <Picture picture={item.picture} pictures={item.pictures} />
      {item.description && <p>{LANG('description')}</p>}
      {item.note && <p><i>{item.note}</i></p>}
      {item.colors && <ItemColors item={item} />}
      {item.yarns && <Palette yarns={item.yarns} />}
      {item.sizing && <Sizing sizing={item.sizing} />}
      <h4>Resouces</h4>
      <Links style={styles.section} links={item.links} vertical />
      <FileUpload folder={item.pk} item={item} />
    </Card>
  )
}

const styles = {
  card: {
    marginTop: 20
  }
}