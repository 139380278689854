import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useGetProductQuery } from '../../store/stripeApi.js';
import Checkout from './Checkout.js';
import Picture from '../Widgets/Picture.js';

const STRIPE_KEY = 'pk_test_51LepXEA8YTXq9RcdWUbCfp7LrpbqJQkiiGlG6e0cAps8L9WIZQsrwC0DsYIMBubd7paePqf0U3esvYEEhHZPW9uI00NpXV1C8m';
const stripePromise = loadStripe(STRIPE_KEY);

export default function ProductDetails() {
  let params = useParams();
  const { data: product } = useGetProductQuery({ accountId: params.accountId, productId: params.productId });

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <Elements stripe={stripePromise}>
      <div>
        <h1>{product.name}</h1>
        <Picture pictures={product.images} />
        <p>{product.id}</p>
        <p>{product.description}</p>
        <p>${(product.priceAmount / 100).toFixed(2)} {product.currency.toUpperCase()}</p>
        <Checkout product={product} accountId={params.accountId} />
      </div>
    </Elements>
  );
};

