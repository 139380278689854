import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Alert, Button } from '@blueprintjs/core';
import { useGetItemQuery, useGetConnectedAllQuery, useDeleteItemMutation } from '../../store/docApi';
import Picture from '../Widgets/Picture';
import MotifCard from '../Motif/MotifCard';
import ItemMetadata from '../Widgets/ItemMetadata';
import ItemColors from '../Widgets/ItemColors';
import FileUpload from '../Widgets/FileUpload';

export default function CollectionDetails() {
  let params = useParams();
  const navigate = useNavigate();
  const { data: item, error, isLoading } = useGetItemQuery({ label: "collection", pk: params.pk, id: params.id });
  const { data: motifs, isLoading: motifsLoading } = useGetConnectedAllQuery({
    _id: `collection/${params.pk}/${params.id}`, container: "design", pk: params.pk });
  const [deleteItem] = useDeleteItemMutation();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  function deleteCollection() {
    setDeleteConfirmOpen(false);
    deleteItem({ label: "collection", pk: item.pk, id: item.id });
    navigate("/collections");
}
  return error ? <span>Error {error.status}</span> :
    isLoading ? (<div>Loading...</div>) :
      <div>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: 'center'}}>
            {item.name && <h2>{item.name}</h2>}
            <span>
                <Button icon='trash' onClick={()=>setDeleteConfirmOpen(true)} />
            </span>
        </div>
        <Picture picture={item.picture} pictures={item.pictures} />
        {item.description && <p>{item.description}</p>}
        <ItemColors item={item} />
        <br/>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '16px' }}>
          {motifsLoading ? <div>Loading...</div> :
            motifs && motifs.map((motif, i) =>
              <MotifCard key={i} motif={motif} colors={item.colors} />
            )}
        </div>
        <br/>
        {false && <FileUpload folder={item.pk} item={item} />}
        <br/>
        <Button onClick={() => navigate(`/motif/create/collection/${item.pk}/${item.id}`)}>Add New Motif</Button>
        <ItemMetadata item={item} />
        <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
                    isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
                    onConfirm={deleteCollection}>
                    <p>Delete this collection?</p>
            </Alert>
      </div>
}
