import { HTMLTable } from '@blueprintjs/core';
import { useGetOrdersQuery } from '../../store/stripeApi';

export default function Orders() {
  const { data: orders, isLoading } = useGetOrdersQuery();

  if (orders) console.log(orders);

  return isLoading ? <div>Loading ... </div> : (
    <div>
      <h2>Orders</h2>
      <HTMLTable bordered compact striped>
        <thead>
          <tr>
            <td>Order #</td>
            <td>Created</td>
            <td>Items</td>
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
        {orders && orders.map((order) => (
          <tr key={order.id}>
            <td>{order.id}</td>
            <td>{new Date(order.created).toLocaleString()}</td>
            <td>
              {order.lineItems && order.lineItems.map((lineItem) => (
                <div key={lineItem.id}>{lineItem.description}<br/>{lineItem.id}</div>
              ))}
            </td>
            <td>${(order.amountTotal/100).toFixed(2)}</td>
          </tr>
        ))}
        </tbody>
      </HTMLTable>
    </div>
  );
};
