import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Checkbox } from '@blueprintjs/core';
import { useGetItemsQuery } from '../../store/docApi';

export default function ShopList() {
  const [userContentOnly, setUserContentOnly] = useState(false);
  const { data: items, error, isLoading } = useGetItemsQuery({ label: "shop" })
  const navigate = useNavigate();

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<div>Loading...</div>) :
      <div>
        {false && <Checkbox checked={userContentOnly} onChange={(e) => setUserContentOnly(e.target.checked)}>My shops only</Checkbox>}
        <div style={styles.list}>
          {items.map((item, i) => (
            <Card style={styles.card} key={i} interactive onClick={() => navigate(`/shop/${item.pk}/${item.id}`)}>
              <div style={styles.cardContent}>
                <img src={item.picture} alt={item.name} style={styles.logo} />
              </div>
            </Card>
          ))}
        </div>
      </div>
}

const styles = {
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  card: {
    margin: 10,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '160px',
    height: '160px',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  }
}