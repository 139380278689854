import { useState } from "react";
import { useStripeConnect } from "../../hooks/useStripeConnect";
import { useGetDashboardLinkQuery } from '../../store/stripeApi';

import { useAccountStatusQuery, useAccountCreateMutation } from "../../store/stripeApi";
import { ConnectAccountOnboarding, ConnectComponentsProvider } from "@stripe/react-connect-js";
import { Button, Callout } from "@blueprintjs/core";

export default function ShopManage({ item }) {
    const stripeAccountId = item && item.stripeAccountId ? item.stripeAccountId : null;
    const [onboardingExited, setOnboardingExited] = useState(false);
    const [connectedAccountId, setConnectedAccountId] = useState(stripeAccountId);
    const stripeConnectInstance = useStripeConnect(connectedAccountId);
    const [accountCreate, { isLoading: accountCreatePending, isError }] = useAccountCreateMutation();
    const { data: accountStatus, isLoading } = useAccountStatusQuery({ accountId: stripeAccountId}, { skip: !stripeAccountId });
    const { data: dashboard } = useGetDashboardLinkQuery({ accountId: item?.stripeAccountId }, { skip: !item?.stripeAccountId });

    async function signUp() {
        try {
            const response = await accountCreate({props: { id: item.id, pk: item.pk, label: item.label }}).unwrap();
            console.log("response", response);
            if (!isError) setConnectedAccountId(response.account);
        } catch (error) {
            console.log("Failed to register account");
        }
    }

    //console.log("Account status for", item.stripeAccountId, accountStatus);

    return isLoading ? <div><br/>Loading shop information...</div> : (
        accountStatus && accountStatus.isReady ? 
            <div>
                <br/>
                <Callout intent="success">Your shop is all set to accept payments.</Callout>
                <br/>
                {dashboard && dashboard.url && <div><a href={dashboard.url} target="_blank" rel="noreferrer">View Stripe Dashboard</a></div>}
            </div> :
            <div style={styles.container}>
                <div style={styles.content}>
                    {!connectedAccountId && <p>Create an Online Shop</p>}
                    {connectedAccountId && !stripeConnectInstance && <h2>Add information to start accepting money</h2>}
                    {!accountCreatePending && !connectedAccountId && <Button onClick={signUp}>Sign up</Button>}
                    {stripeConnectInstance && (
                        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                            <ConnectAccountOnboarding onExit={() => setOnboardingExited(true)} />
                        </ConnectComponentsProvider>
                    )}
                    {isError && <p className="error">Something went wrong!</p>}
                    {(connectedAccountId || accountCreatePending || onboardingExited) && (
                        <div style={styles.devCallout}>
                            {connectedAccountId && <p>Your connected account ID is: <code className="bold">{connectedAccountId}</code></p>}
                            {accountCreatePending && <p>Creating a connected account...</p>}
                            {onboardingExited && <p>The Account Onboarding component has exited</p>}
                        </div>
                    )}
                </div>
                </div>
    );
}

const styles = {
    container: {
        paddingTop: 40
  },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        width: '420px',
        //paddingTop: '40px',
        marginBottom: '110px'
    },
    devCallout: {
        position: 'fixed',
        bottom: '110px',
        borderRadius: '4px',
        boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.12), 0px 15px 35px 0px rgba(48, 49, 61, 0.08)',
        padding: '9px 12px',
        backgroundColor: '#ffffff',
    }

}