import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Callout, Colors, Spinner, Tooltip } from '@blueprintjs/core';
import { useGetItemQuery } from '../../store/docApi';
import { usePackagePatternMutation } from '../../store/appApi';
import Instructions from './Instructions';
import Overview from './Overview';
import ProductCreate from '../Shop/ProductCreate';
import { appstyles } from '../../styles/appstyles';
import ItemMetadata from '../Widgets/ItemMetadata';

export default function Pattern() {
  let params = useParams();
  const patternPk = params.pk;
  const patternId = params.id;

  // TODO: do we need projectId and stepId?
  const projectId = params.projectId;
  const stepId = params.stepId;

  const { data: item, isLoading } = useGetItemQuery({ label: "pattern", pk: params.pk, id: params.id });
  const [packagePattern, result] = usePackagePatternMutation();

  const [showPublish, setShowPublish] = useState(false);
  const [selectedTab, setSelectedTab] = useState(params.projectId ? "instructions" : "overview");
  const [currentLang, setCurrentLang] = useState('en');
  const [statusMessage, setStatusMessage] = useState("");
  const navigate = useNavigate();

  function tabStyle(selected) {
    return selected ? {
      backgroundColor: Colors.TURQUOISE3,
      color: 'white'
    } : {}
  }

  function edit() {
    navigate(`/item/pattern/${item.pk}/${item.id}`)
  }

  function publish() {
    setShowPublish(true);
  }

  async function callPackagePattern() {
    await packagePattern({ label: "pattern", pk: patternPk, id: patternId })
  }

  useEffect(() => {
    if (result.isSuccess) {
      setStatusMessage("Published.");
    }
  }, [result]);

  return isLoading ? <Spinner style={appstyles.spinner} /> :
    item &&
    <div><div style={styles.panel}>
      <div>
        <h3>{item.name}</h3>
        {/*node && <i> ({node.name} {node.stripeProductId && node.stripeProductId})</i>*/}
        {item.author && <p>by <Link to={`/@${item.author}`}>@{item.author}</Link> </p>}
      </div>
      <div>
        {item.lang && item.lang.map((lang, i) =>
          <Button small key={i} style={tabStyle(lang === currentLang)}
            onClick={() => setCurrentLang(lang)}>{lang}</Button>
        )}
      </div>
    </div>
      <Button style={tabStyle(selectedTab === "overview")}
        onClick={() => setSelectedTab("overview")}>Overview</Button>
      &nbsp;&nbsp;
      <Button style={tabStyle(selectedTab === "instructions")}
        onClick={() => setSelectedTab("instructions")}>Instructions</Button>
      &nbsp;&nbsp;
      <Button minimal icon="edit" onClick={() => edit()} disabled />
      <Tooltip content="Publish" placement="bottom">
        <Button minimal icon="cloud-upload" onClick={() => callPackagePattern()} />
      </Tooltip>
      <Tooltip content="Sell" placement="bottom">
        <Button minimal icon="shop" onClick={() => publish()} />
      </Tooltip>
      <br />
      {statusMessage && <Callout intent="success" style={{ marginTop: 16}}>{statusMessage}</Callout>}
      {showPublish && <ProductCreate item={item} />}
      <br />
      {selectedTab === "overview" && <Overview patternId={patternId} patternPk={patternPk} lang={currentLang} />}
      {selectedTab === "instructions" && <Instructions patternId={patternId} patternPk={patternPk} projectId={projectId} stepId={stepId} lang={currentLang} />}
      <ItemMetadata item={item} />
    </div>
}

const styles = {
  panel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  img: {
    height: 100,
    width: 100,
    borderRadius: 5
  }
};
