import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Alert, Button, InputGroup, Label, TextArea } from '@blueprintjs/core';
import { useGetItemQuery, useDeleteItemMutation, useUpdatePropertyMutation } from '../../store/docApi';
import ItemMetadata from '../Widgets/ItemMetadata';
import { formatDateTime } from '../../utils';

export default function NoteDetails() {
    const params = useParams();
    const id = params.id;
    const pk = params.pk;
    const { data: item } = useGetItemQuery({ label: 'note', pk: pk, id: id });
    const [deleteItem] = useDeleteItemMutation();
    const [updateProperty] = useUpdatePropertyMutation();
    const navigate = useNavigate();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState();
    const [note, setNote] = useState();
    const [startDate, setStartDate] = useState();

    useEffect(() => {
        if (item) {
            setName(item.name);
            setNote(item.note);
            setStartDate(item.startDate);
        }
    }, [item]);

    function deleteConfirm() {
        setDeleteConfirmOpen(true);
    }

    function deleteNote() {
        setDeleteConfirmOpen(false);
        deleteItem({ label: "note", pk: item.pk, id: item.id });
        navigate("/notes");
    }

    const saveNote = () => {
        setIsEditing(false);
        if (name !== item.name)
            updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'name', value: name });
        if (note !== item.note)
            updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'note', value: note });
        if (startDate !== item.startDate)
            updateProperty({ label: item.label, pk: item.pk, id: item.id, name: 'startDate', value: startDate });
    };

    const handleChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handlePrint = () => {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(`
        <html><head><title>Print Note</title>
        <style>
          @page {
            margin: 0
          }
          body {
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            margin: 1in;
            line-height: 1;
          }
          h3 {
            margin-bottom: 10px;
          }
          p {
            font-size: 12pt;
            line-height: 1;
          }
        </style>  
        </head>
        <body>
            <p><b>${item.name}</b></p>
            <p>${item.note}</p>
        </body>
        </html>`);
        printWindow.document.close();
        printWindow.print();
    };

    return item && <div>
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                <h2>{item.name}</h2>
                {isEditing ?
                    <span>
                        <Button icon='tick' onClick={() => saveNote()} />
                        &nbsp;
                        <Button icon='cross' onClick={() => setIsEditing(false)} />
                    </span> :
                    <span>
                        <Button icon='edit' onClick={() => setIsEditing(true)} />
                        &nbsp;&nbsp;
                        <Button icon='print' onClick={() => handlePrint()} />
                        &nbsp;
                        <Button icon='trash' onClick={() => deleteConfirm()} />
                    </span>}
            </div>
            {isEditing ? <div>
                <Label>Name:</Label>
                <InputGroup type="text" value={name} onChange={handleChange(setName)} />
                <Label>Date:</Label>
                <InputGroup type="date" value={startDate} onChange={handleChange(setStartDate)} />
                <Label>Note:</Label>
                <TextArea value={note} onChange={handleChange(setNote)} />
            </div> : <div>
                <p>{formatDateTime(item.startDate)}</p>
                {item.connections && item.connections.map((connection, i) => (
                    <li key={i}>
                        {connection._name} {connection._type} {connection._step_id} <Link to={`/library/${connection._id}`}>{connection._id}</Link>
                    </li>
                ))}
                <br/>
                <p>{item.note}</p>
            </div>}
        </div>
        <ItemMetadata item={item} />
        <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
            isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
            onConfirm={() => deleteNote()}>
            <p>Delete this note?</p>
        </Alert>
    </div>

};
