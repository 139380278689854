import { useState } from 'react';
import { Button } from '@blueprintjs/core';

export default function Sizing({sizing}) {
  const [selectedSize, setSelectedSize] = useState(sizing && sizing.sizes ? sizing.sizes[0] : "");

  return sizing && (
    <div style={styles.container}>
      <b>Sizing</b>
      {sizing.description && <p>{sizing.description}</p>}
      {sizing.sizes && <div style={styles.listContainer}>
        {sizing.sizes.map((item) => (
          <Button key={item.id} onClick={() => setSelectedSize(item)}
            style={item.id === selectedSize.id ? styles.selectedListItem : styles.listItem}>{item.name}</Button>
        ))}
      </div>}
      {sizing.measurements && <div style={styles.card}>
        <span style={styles.title}>{selectedSize.name}</span>
        {sizing.measurements.map((field) => (
          <div key={field.id} style={styles.fieldContainer}>
            <span style={styles.fieldName}>{field.name}:</span>
            <span style={styles.fieldValue}>{selectedSize[field.id]}</span>
          </div>
        ))}
      </div>}
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#f5f5f5'
  },
  listContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 20
  },
  listItem: {
    backgroundColor: '#ddd',
    padding: 10,
    borderRadius: 5,
    margin: 5
  },
  selectedListItem: {
    backgroundColor: 'teal',
    color: 'white',
    padding: 10,
    borderRadius: 5,
    margin: 5
  },
  listItemText: {
    fontSize: 16,
    color: '#000'
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 15,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 3
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10
  },
  fieldContainer: {
    flexDirection: 'row',
    marginBottom: 5
  },
  fieldName: {
    fontWeight: 'bold',
    marginRight: 5
  },
  fieldValue: {
    color: '#555'
  }
};
