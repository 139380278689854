import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Divider, Tag, HTMLSelect, Elevation, InputGroup, Colors, EntityTitle, H3, Icon } from "@blueprintjs/core";
import { useGetItemQuery, useGetSubitemsQuery } from '../../store/docApi';
import Links from '../Widgets/Links';
import Notes from '../Widgets/Notes';

const defaultTheme = { primary: Colors.CERULEAN1, primaryText: "white", bannerBackground: "ghostwhite" };

function ShopDetailsPage() {
  const navigate = useNavigate();
  let params = useParams();
  const { data: shop } = useGetItemQuery({ label: "shop", pk: params.pk, id: params.pk });
  const { data: products } = useGetSubitemsQuery({ label: "product", pk: params.pk });
  const [theme, setTheme] = useState(defaultTheme);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [sortBy, setSortBy] = useState("Newest");
  const [searchQuery, setSearchQuery] = useState("");
  const [categories, setCategories] = useState(["All"]);
  const sortOptions = ["Newest", "Popular", "Price: Low to High", "Price: High to Low"];
  const [viewMode, setViewMode] = useState("products");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedImage, setSelectedImage] = useState(0);

  useEffect(() => {
    if (shop) {
      shop.categories && setCategories(["All", ...shop.categories]);
      if (shop.theme) {
        setTheme({ ...defaultTheme, ...shop.theme });
      }
    }
  }, [shop]);

  useEffect(() => {
    if (products) {
      const _filteredProducts = products.filter(product => {
        const matchesCategory = selectedCategory === "All" || product.category === selectedCategory;
        const matchesSearch = searchQuery === "" || (
          product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.details.some(detail =>
            detail.toLowerCase().includes(searchQuery.toLowerCase())
          )
        );
        return matchesCategory && matchesSearch;
      });
      setFilteredProducts(_filteredProducts);
    }
  }, [products, selectedCategory, searchQuery]);

  useEffect(() => {
    if (products && params.id) {
      const product = products.find(product => product.id === params.id);
      setSelectedProduct(product);
    }
  }, [params.id, products]);

  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return parts.map((part, index) => {
      if (part.toLowerCase() === searchTerm.toLowerCase()) {
        return (
          <span key={index} style={{ backgroundColor: '#FFE3B3', borderRadius: '2px' }} >
            {part}
          </span>
        );
      }
      return part;
    });
  };

  const dynamicStyles = {
    tabButton: { borderWidth: "0 0 4px 0", borderStyle: 'solid', borderColor: theme.primary },
    priceButton: { backgroundColor: theme.primary, color: theme.primaryText }
  }

  const ProductDetails = ({ product }) => {
    return product && (<div>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Link onClick={() => { setSelectedProduct(null); navigate(`/explore/${shop.pk}`) }}
          to={`/explore/${shop.pk}`} style={{ color: theme.primary }}>All</Link>
        <Icon icon="chevron-right" />
        <span>{selectedProduct.name}</span>
      </div>
      <br />

      <div style={{ display: 'flex' }}>
        <Card elevation={Elevation.ONE}>
          <div style={{ display: 'flex', gap: 24, flexWrap: 'wrap' }}>
            <div>
              <img src={product.pictures[selectedImage]}
                alt={`${product.name} preview ${selectedImage + 1}`}
                style={{ width: 300, objectFit: 'contain', backgroundColor: '#f5f8fa', borderRadius: '3px' }}
              />
              <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                {product.pictures.map((img, index) => (
                  <img key={index} src={img} alt={`Thumbnail ${index + 1}`}
                    style={{
                      width: '80px', height: '80px', objectFit: 'cover', cursor: 'pointer',
                      border: selectedImage === index ? '2px solid #106BA3' : '2px solid transparent',
                      borderRadius: '3px'
                    }}
                    onClick={() => setSelectedImage(index)}
                  />
                ))}
              </div>
            </div>

            <div style={{ minWidth: 300, maxWidth: 400 }}>
              <h2 style={{ margin: '0 0 10px 0' }}>{product.name}</h2>
              <div style={{ fontWeight: 'bold' }}>{product.shopName}</div>
              <br />
              <Divider />
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px 0', flexWrap: 'wrap' }}>
                <span style={styles.price}>${(product.priceAmount / 100).toFixed(2)}</span>
                <Button text="Add to Cart" style={dynamicStyles.priceButton} />
              </div>
              <p>{product.description}</p>
              {product.note && <Notes notes={product.note} />}
            </div>
          </div>
        </Card>
      </div>
    </div>
    );
  };

  const BlankProducts = () => {
    return <div>
      <Card elevation={Elevation.ONE}>
        <h3>No products found</h3>
        <p>There are no products available in this shop.</p>
      </Card>
    </div>
  };

  const ProductsGrid = () => {
    return <div>
      <Card
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <InputGroup leftIcon="search" placeholder="Search products..."
            value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ flex: 1 }}
          />
          <HTMLSelect value={selectedCategory} options={categories}
            onChange={(e) => setSelectedCategory(e.target.value)} />
          <HTMLSelect value={sortBy} options={sortOptions}
            onChange={(e) => setSortBy(e.target.value)} />
        </div>
      </Card>
      <br />
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '16px' }}>
        {filteredProducts.map(product => (
          <Card key={product.id} elevation={Elevation.TWO} interactive onClick={() => navigate(`/explore/${product.pk}/${product.id}`)}
            style={{ display: 'flex', flexDirection: 'column' }} >
            <img src={product.picture} alt={product.name}
              style={styles.productImage} />
            <EntityTitle ellipsize heading={H3} title={highlightText(product.name, searchQuery)} />
            <p style={styles.productDescription}>{highlightText(product.description, searchQuery)}</p>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 'auto' }}>
              <span style={styles.price}>${(product.priceAmount / 100).toFixed(2)}</span>
              <Button small style={dynamicStyles.priceButton}
                text="Add to Cart" />
            </div>
          </Card>
        ))}
      </div>
    </div>
  };

  const Banner = () => {
    return <div>
      {shop.bannerImage && <img src={shop.bannerImage} alt="Banner" style={styles.bannerImage} />}
      {!shop.bannerImage && <div style={{ height: '100px', backgroundColor: theme.bannerBackground || 'ghostwhite' }} />}
      <div>
        <div style={{ marginTop: -50, padding: 16 }}>
          <div style={{ display: 'flex', alignItems: 'flex-end', gap: 16 }}>
            {shop.profileImage &&
              <img src={shop.profileImage} alt={shop.name} style={styles.profileImage} />
            }
            <div style={{ paddingBottom: 4 }}>
              <h2 style={{ marginBottom: 0 }}>{shop.name}</h2>
              {shop.designer && <span>{shop.designer}</span>}
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: '16px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
        {shop.tags && shop.tags.map(tag => (
          <Tag key={tag} minimal>{tag}</Tag>
        ))}
      </div>
    </div>
  };

  const About = () => {
    return <div>
      <Card>
        <h3>About</h3>
        <p>{shop.description}</p>
        <br />
        <Divider />
        {shop.links && <Links links={shop.links} iconColor={theme.primary} />}
        {false && <center>
          {shop.picture && <img src={shop.picture} alt={shop.name} style={{ maxWidth: "300px" }} />}
        </center>}
      </Card>
    </div>
  };

  return shop && (
    <div>
      <Banner />
      <br />
      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: '16px', width: "100%" }}>
          <div>
            <Button minimal text="Products" onClick={() => setViewMode("products")}
              style={viewMode === 'products' ? dynamicStyles.tabButton : {}} />
            <Button minimal text="About" onClick={() => setViewMode("about")}
              style={viewMode === 'about' ? dynamicStyles.tabButton : {}} />
          </div>
          <Button small minimal icon="heart" />
        </div>
        <br />
        {viewMode === 'about' && <About />}

        {viewMode === 'products' &&
          <div>
            {selectedProduct ? <ProductDetails product={selectedProduct} theme={theme} /> :
              (products && products.length > 0) ? <ProductsGrid /> :
                <BlankProducts />}
          </div>}
      </div>
    </div>
  );
};

const styles = {
  productDescription: {
    marginTop: 8,
    marginBottom: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
    color: Colors.DARK_GRAY5
  },
  bannerImage: { display: 'inline-flex', objectFit: 'cover', width: '100%', height: '100px' },
  profileImage: { display: 'inline-flex', objectFit: 'cover', width: '100px', height: '100px', borderRadius: '8px', border: '4px solid white' },
  productImage: { width: '100%', height: '200px', objectFit: 'cover', marginBottom: '12px', borderRadius: '4px' },
  price: { fontSize: 16, fontWeight: 500 }
}

export default ShopDetailsPage;

