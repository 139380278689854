import { useParams } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';
import { useGetUserQuery } from '../../store/docApi';
import ProjectDetails from './ProjectDetails';
import ProjectList from "./ProjectList";

export default function Projects() {
  const params = useParams();
  const { data, error, isLoading } = useGetUserQuery();

  return error ? <span>Error {error.status}</span> :
    isLoading ? (<Spinner />) :
      data && params.id ? 
        <ProjectDetails username={data.name} projectId={params.id} connectionIndex={params.connectionIndex} /> :
        <ProjectList username={data.name} referrer="projects"/>
}
