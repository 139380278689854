import { useNavigate } from "react-router-dom";
import { Card, Icon, Spinner } from "@blueprintjs/core";
import { useGetHowToItemsQuery } from "../../store/appApi";
import { appstyles } from "../../styles/appstyles";
import { getCraftIcon } from "../../utils";

export default function Library() {
  const navigate = useNavigate();
  const { data: items, error, isLoading } = useGetHowToItemsQuery({ label: "pattern" });

  document.title = "Library";
  return <>
    <h2>Library</h2>
    {error ? <span>Error {error.status}</span> :
      isLoading ? (<Spinner style={appstyles.spinner} />) : <div style={appstyles.tileGrid}>
        {items.map((item, i) => (
          <Card key={i} style={appstyles.tile} interactive={true}
            onClick={() => navigate(`/library/pattern/${item.pk}/${item.id}`)}>
            {item.picture ? <img style={appstyles.tileImg} src={item.picture} alt={item.name} /> :
              item.pictures && item.pictures.length > 0 ? <img style={appstyles.tileImg} src={item.pictures[0]} alt={item.name} /> :
                <div style={appstyles.tileNoimg}><Icon icon={getCraftIcon(item.craft)} size={60} /></div>}
            <div style={appstyles.tileContent}>
              <div style={appstyles.tileTitle}>{item.name}</div>
              {<span style={appstyles.tileLink}>@{item.pk}</span>}
            </div>
          </Card>
        ))}
      </div>
    }
  </>
}
