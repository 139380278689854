import Markdown from "./Markdown";

export default function Notes({ notes }) {

  if (typeof notes === 'string') {
    return <div><Markdown text={notes} /></div>
  }
  else if (Array.isArray(notes)) {
  return notes && <div>
    {notes.map((note, i) => {
      return (typeof note === 'object') ? 
      <p key={i}><b>{note.name}</b><br/>{note.note}</p> :
      <p key={i}>{note}</p>
    })}
  </div>
  }
}
