import YouTube from "react-youtube";

export function Video({ video }) {
    let opts = {
        width: '320',
        height: '180',
        playerVars: {
            start: video.start ? video.start : 0
        }
    };
    return <div style={styles.video}>
        {video.type === "youtube" && <YouTube opts={opts} videoId={video.id} />}
    </div>
}

const styles = {
    video: {
        margin: 20
    }
}