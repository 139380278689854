import { useNavigate } from "react-router-dom";
import { Card, Colors, Icon } from "@blueprintjs/core";

export default function HomePage() {
  const navigate = useNavigate();
  return (
    <center>
      <div style={styles.grid} >
        <Card interactive style={styles.card} onClick={() => navigate('/explore')}>
          <Icon icon="search" size={32} style={styles.icon} />
          <div style={styles.title}>Explore</div>
          <div style={styles.description}>(Marketplace)</div>
        </Card>
        <Card interactive style={styles.card} onClick={() => navigate('/projects')}>
          <Icon icon="grid-view" size={32} style={styles.icon} />
          <div style={styles.title}>Projects</div>
          <div style={styles.description}>(Knits and other creations)</div>
        </Card>
        <Card interactive style={styles.card} onClick={() => navigate('/library')}>
          <Icon icon="manual" size={32} style={styles.icon} />
          <div style={styles.title}>Library</div>
          <div style={styles.description}>(Acquired Content)</div>
        </Card>
      </div>
      <div style={styles.grid} >
        <Card interactive style={styles.card} onClick={() => navigate('/patterns')}>
          <Icon icon="layout-grid" size={32} style={styles.icon} />
          <div style={styles.title}>Patterns</div>
          <div style={styles.description}>(Personal Designs)</div>
        </Card>
        <Card interactive style={styles.card} onClick={() => navigate('/motifs')}>
          <Icon icon="shapes" size={32} style={styles.icon} />
          <div style={styles.title}>Motifs</div>
          <div style={styles.description}>(Personal Designs)</div>
        </Card>
        <Card interactive style={styles.card} onClick={() => navigate('/collections')}>
          <Icon icon="box" size={32} style={styles.icon} />
          <div style={styles.title}>Collections</div>
          <div style={styles.description}>(Personal Designs)</div>
        </Card>
      </div>
    </center>
  );
}

const styles = {
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: 24,
  },
  card: {
    margin: 16,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 200,
  },
  image: {
    width: 160,
    borderRadius: 20

  },
  title: {
    textTransform: 'uppercase',
    fontSize: 18,
    color: Colors.CERULEAN2,
  },
  description: {
    fontStyle: 'italic',
    paddingTop: 8,
  },
  icon: {
    color: Colors.CERULEAN2,
    margin: 16
  }
}

