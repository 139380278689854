import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebaseConfig';
import { Spinner } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { store } from "./store/store";
import { loginAction, selectAccessToken } from "./store/userSlice";
import { docApi } from "./store/docApi";
import Layout from './components/Layout';
import ProfilePage from "./components/Pages/ProfilePage";
import Patterns from "./components/Pattern/Patterns";
import Pattern from "./components/Pattern/Pattern";
import PatternCreate from "./components/Pattern/PatternCreate";
import Library from "./components/Library/Library";
import MotifCreate from "./components/Motif/MotifCreate";
import WelcomePage from "./components/Pages/WelcomePage";
import HomePage from "./components/Pages/HomePage";
import Motifs from "./components/Motif/Motifs";
import LoginPage from "./components/Pages/LoginPage";
import Collections from "./components/Collection/Collections";
import UserPage from "./components/Pages/UserPage";
import ProjectCreate from "./components/Project/ProjectCreate";
import Items from "./components/Item/Items";
import Projects from "./components/Project/Projects";
import SearchPage from "./components/Pages/SearchPage";
import EventList from "./components/Event/EventList";
import EventDetails from "./components/Event/EventDetails";
import Nodes from "./components/Graph/Nodes";
import NodeCreate from "./components/Graph/NodeCreate";
import ShopManage from "./components/Shop/ShopManage";
import ShopList from "./components/Shop/ShopList";
import ShopDetails from "./components/Shop/ShopDetails";
import ProductDetails from "./components/Shop/ProductDetails";
import CheckoutComplete from "./components/Shop/CheckoutComplete";
import Orders from "./components/Shop/Orders";
import Ravelry from "./Ravelry/Ravelry";
import CollectionDetails from "./components/Collection/CollectionDetails";
import HowtoPatternDetails from "./components/Library/HowtoPatternDetails";
import MotifDetails from "./components/Motif/MotifDetails";
import CollectionCreate from "./components/Collection/CollectionCreate";
import NoteDetails from "./components/Note/NoteDetails";
import Notes from "./components/Note/Notes";
import NoteCreate from "./components/Note/NoteCreate";
import ExplorePage from "./components/Explore/ExplorePage.js";
import ShopDetailsPage from "./components/Explore/ShopDetails.js";

export default function App() {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState();
  document.title = "STITCH PORTAL";
  let location = useLocation();
  let dispatch = useDispatch();
  const currentToken = useSelector(selectAccessToken);

  function updateToken(idToken) {
    if (currentToken !== idToken) {
      console.log("Resetting token");
      store.dispatch(loginAction({ accessToken: idToken }));
      dispatch(docApi.util.resetApiState());
    }
  }

  function handleAuthStateChanged(user) {
    setUser(user);
    if (user) {
      console.log("logged in as " + user.email);
      auth.currentUser.getIdToken()
        .then(function (idToken) {
          updateToken(idToken);
          setInitializing(false);
        })
        .catch(function (error) {
          console.log("Error refreshing token", error);
          updateToken(null);
          setInitializing(false);
        });
    } else {
      console.log("unauthenticated");
      updateToken(null);
      setInitializing(false);
    }
  }

  useEffect(() => {
    return onAuthStateChanged(auth, handleAuthStateChanged);
  });

  return initializing ? <Spinner style={{ padding: 200 }} /> :
    location.pathname.startsWith("/@") && location.pathname.length > 3 ? (
      <Layout showLogo={false}><UserPage /></Layout>
    ) : user ? (
      <Layout showLogo={true}>
        <Routes>
          <Route exact path='/' element={<HomePage />} />
          <Route path='/search' element={<SearchPage />} />
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/patterns' element={<Patterns />} />
          <Route path='/patterns/:pk/:id' element={<Patterns />} />
          <Route path='/pattern/:pk/:id' element={<Pattern />} />
          <Route path='/pattern/create' element={<PatternCreate />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/project/:pk/:id' element={<Projects />} />
          <Route path='/project/:pk/:id/:connectionIndex' element={<Projects />} />
          <Route path='/project/:pk/:id/:connectionIndex/:stepId' element={<Projects />} />
          <Route path='/project/create/pattern/:pk/:id' element={<ProjectCreate />} />
          <Route path='/project/create' element={<ProjectCreate />} />
          <Route path='/library' element={<Library />} />
          <Route path='/library/pattern/:pk/:id' element={<HowtoPatternDetails />} />
          <Route path='/collections' element={<Collections />} />
          <Route path='/collection/:pk/:id' element={<CollectionDetails />} />
          <Route path='/collection/create' element={<CollectionCreate />} />
          <Route path='/motifs' element={<Motifs />} />
          <Route path='/motif/:pk/:id' element={<MotifDetails />} />
          <Route path='/motif/create/collection/:pk/:id' element={<MotifCreate />} />
          <Route path='/motif/create' element={<MotifCreate />} />
          <Route path='/notes' element={<Notes />} />
          <Route path='/note/:pk/:id' element={<NoteDetails />} />
          <Route path='/note/create' element={<NoteCreate />} />
          <Route path='/events' element={<EventList />} />
          <Route path='/event/:pk/:id' element={<EventDetails />} />
          <Route path='/item/:label/:pk/:id' element={<Items />} />
          <Route path='/item/:label/:pk' element={<Items />} />
          <Route path='/item/:label' element={<Items />} />
          <Route path='/items' element={<Items />} />
          <Route path='/nodes' element={<Nodes />} />
          <Route path='/node/create' element={<NodeCreate />} />
          <Route path='/node/:pk/:id' element={<Nodes />} />
          <Route path='/shop/manage' element={<ShopManage />} />
          <Route path='/shops' element={<ShopList />} />
          <Route path='/shop/:pk/:id' element={<ShopDetails />} />
          <Route path='/shop/checkout' element={<CheckoutComplete />} />
          <Route path='/product/:accountId/:productId' element={<ProductDetails />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/ravelry' element={<Ravelry />} />
          <Route path='/explore' element={<ExplorePage />} />
          <Route path='/explore/:pk' element={<ShopDetailsPage />} />
          <Route path='/explore/:pk/:id' element={<ShopDetailsPage />} />
          <Route path='*' element={<HomePage />} />
        </Routes>
      </Layout>
    ) : (
      location.pathname.startsWith("/login") ?
        <LoginPage /> :
        <WelcomePage />
    )

}
