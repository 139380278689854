import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useGetItemQuery } from '../../store/docApi';
import Picture from '../Widgets/Picture';
import FileUpload from '../Widgets/FileUpload';
import ShopManage from './ShopManage';
import ShopProducts from './ShopProducts';
import { getDisplayValue } from '../../utils';
import { Button } from '@blueprintjs/core';
import ItemMetadata from '../Widgets/ItemMetadata';
import Links from '../Widgets/Links';

export default function ShopDetails() {
    let params = useParams();
    const [view, setView] = useState("details");

    const { data: item } = useGetItemQuery({ label: "shop", pk: params.pk, id: params.id });

    return item &&
        <div style={styles.content}>
            {item.name && <h2>{item.name} </h2>}
            <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }} >
                <Button text="Details" onClick={() => setView("details")} intent={view === 'details' ? 'primary' : 'none'} />
                <Button text="Products" onClick={() => setView("products")} intent={view === 'products' ? 'primary' : 'none'} />
                <Button text="Manage" onClick={() => setView("manage")} intent={view === 'manage' ? 'primary' : 'none'} />
            </div>
            {view === "details" && <div>
                <hr/>
                <Picture picture={item.picture} pictures={item.pictures} />
                {false && Object.keys(item).map((prop, i) =>
                    <div key={i}>
                        <b>{prop}</b> : {getDisplayValue(prop, item[prop])}
                    </div>
                )}
                <hr />
                {false && <FileUpload folder={item.pk} item={item} itemType="item" />}
                <Links links={item.links} vertical/>
            </div>}
            {view === "products" && item.stripeAccountId && <ShopProducts stripeAccountId={item.stripeAccountId} />}
            {view === "manage" && <ShopManage item={item} />}
            <ItemMetadata item={item} />
        </div>
}

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column'
    }
}
