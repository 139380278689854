import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from '@blueprintjs/core';
import { useGetColorchartsQuery } from '../../store/appApi';
import MotifCard from "./MotifCard";
import { appstyles } from '../../styles/appstyles';

export default function Motifs() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetColorchartsQuery();

  document.title = "Motifs";

  let items = [];
  if (data) {
    items = [...data]
    items.sort((p1, p2) => { return p2._ts - p1._ts });
  }

  if (error) {
    console.log(error)
  }

  return <div>
    <h2>Motifs</h2>
    <Button text="New Motif" onClick={() => navigate('/motif/create')} />
    <br/><br/>
    {error ? <span>Error {error.status}</span> :
    isLoading ? (<Spinner style={appstyles.spinner} />) :
    <div>
      <div style={styles.list}>
        {items.map((motif, i) => (
          <MotifCard motif={motif} key={i} />
        ))}
      </div>
    </div>}
  </div>
}

const styles = {
  list: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center"
  }
}
