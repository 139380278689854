import { useNavigate } from "react-router-dom";
import { useGetItemsQuery } from '../../store/docApi';
import { Button, Card, Icon, Spinner } from '@blueprintjs/core';
import Error from '../Widgets/Error';
import { appstyles } from '../../styles/appstyles';

export default function Collections() {
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetItemsQuery({ label: 'collection' })

  let items = [];
  if (data) {
    items = [...data]
    items.sort((p1, p2) => { return p2._ts - p1._ts });
  }

  if (error) {
    console.log(error)
  }

  return <>
    <h2>Collections</h2>
    <Button onClick={() => navigate('/collection/create')}>New Collection</Button>
    {error ? <Error /> :
      isLoading ? (<Spinner style={appstyles.spinner} />) :
        <div style={appstyles.tileGrid}>
          {items.map((collection, i) => {
            let pic = collection.picture ? collection.picture : collection.pictures && collection.pictures.length > 0 ? collection.pictures[0] : null;
            return (
              <Card key={i} style={appstyles.tile} interactive
                onClick={() => navigate(`/collection/${collection.pk}/${collection.id}`)}>
                {pic ? <img style={appstyles.tileImg} src={pic} alt={collection.name} /> :
                  <div style={appstyles.tileNoimg}><Icon icon='box' size={80} /></div>}
                <div style={appstyles.tileTitle}>
                  {collection.name}
                </div>
              </Card>
            )
          }
          )}
        </div>
    }</>
}
