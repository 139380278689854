import Section from './Section';
import Palette from './Palette';
import Picture from '../Widgets/Picture';
import Notes from '../Widgets/Notes';
import KnitChart from '../Widgets/KnitChart';
import Glossary from './Glossary';
import ColorChart from '../Widgets/ColorChart';
import { Video } from '../Widgets/Video';
import Links from '../Widgets/Links';

export default function PartDetails({ item, subitem, subitems, project, palette, currentStepIn, depth }) {
  function getSteps() {
    if (subitem.step_id) {
      let motif = subitems.find(s => s.id === subitem.step_id);
      if (motif)
        return motif.steps;
    }
    return subitem.steps;
  }

  return (
    <div>
      <Picture picture={subitem.picture} pictures={subitem.pictures} />
      {subitem.description && <span>{subitem.description}</span>}
      {subitem.video && <Video video={subitem.video} />}
      <Links links={subitem.links} />
      {subitem.knitchart && <KnitChart steps={getSteps()} item={item} chartid={subitem.id} />}
      <Notes notes={subitem.notes} />
      {subitem.label === "section" || subitem.label === "part" || subitem.label === "motif" || subitem.label === "instructions" ?
        <Section item={item} subitem={subitem} currentStepIn={currentStepIn} subitems={subitems} project={project} palette={palette} depth={depth} /> :
        subitem.label === "palette" ? <Palette item={item} subitem={subitem} /> :
        subitem.label === "glossary" ? <Glossary item={item} subitem={subitem} /> :
        subitem.label === "colorchart" ? <ColorChart item={item} steps={subitem.steps} /> : <span>...</span>
      }
    </div>
  )
}
