import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Button, Colors, ProgressBar, Spinner } from '@blueprintjs/core';
import { useUpdatePropertyMutation, useDeleteItemMutation } from '../../store/docApi';
import { useGetProjectQuery } from "../../store/appApi";
import { useDeleteImageMutation } from '../../store/mediaApi';
import Links from "../Widgets/Links";
import FileUpload from '../Widgets/FileUpload';
import Instagram from '../Widgets/Instagram';
import { ProjectWizard } from './ProjectWizard';
import { appstyles } from '../../styles/appstyles';
import ItemMetadata from '../Widgets/ItemMetadata';
import ItemColors from '../Widgets/ItemColors';


const imgUrl = "https://m.stitchso.com/img/";

export default function ProjectDetails({ projectId, username, connectionIndex }) {
    const navigate = useNavigate();
    const { data: item, isLoading, isError, error } = useGetProjectQuery({ pk: username, id: projectId });
    const [updateProperty, { isLoading: isUpdating }] = useUpdatePropertyMutation();
    const [deleteItem] = useDeleteItemMutation();
    const [deleteImage] = useDeleteImageMutation();
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteProjectConfirmOpen, setDeleteProjectConfirmOpen] = useState(false);
    const [deleteUrl, setDeleteUrl] = useState();
    const [showAddPicture, setShowAddPicture] = useState(false);
    const [pattern, setPattern] = useState();
    const [view, setView] = useState('instructions');
    if (item) document.title = item.name;

    useEffect(() => {
        if (item) {
            let index = connectionIndex && connectionIndex < item.connections.length ? connectionIndex : 0;
            item.connections && item.connections.length > 0 && setPattern(item.connections[index].pattern);
        }
    }, [item, connectionIndex]);

    function tabStyle(selected) {
        return selected ? {
            backgroundColor: Colors.TURQUOISE3,
            color: 'white'
        } : {}
    }

    function edit() {
        navigate(`/item/project/${item.pk}/${item.id}`)
    }

    function deleteProject() {
        deleteItem({ label: item.label, pk: item.pk, id: item.id });
        navigate("/projects");
    }

    function addPicture() {
        setShowAddPicture(true);
    }

    function deletePictureConfirm(url) {
        setDeleteUrl(url);
        setDeleteConfirmOpen(true);
    }

    function deletePicture() {
        setDeleteConfirmOpen(false);
        if (deleteUrl.startsWith(imgUrl)) {
            deleteImage({ name: deleteUrl.substr(imgUrl.length) })
        }
        let newpictures = item.pictures.filter((el) => { return el !== deleteUrl });
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "pictures", value: newpictures })
    }

    function setPicture(picture) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "picture", value: picture })
    }

    function updateSummary(summary) {
        updateProperty({ label: item.label, pk: item.pk, id: item.id, name: "summary", value: summary })
    }

    function getPictureName(picture) {
        let tok = picture.split("/");
        return tok[tok.length - 1];

    }

    console.log(error);

    const ProjectDetails = () => {
        return <div>
            {item.description && <p>{item.description}</p>}
            <div style={{ marginTop: 16, marginBottom: 16 }}>
                <Button onClick={() => addPicture()} >Add picture</Button>
                &nbsp;&nbsp;
                <Button icon='edit' onClick={() => edit()} ></Button>
                &nbsp;&nbsp;
                <Button icon={item.summary ? 'eye-on' : 'eye-off'}
                    onClick={() => updateSummary(!item.summary)} />
                &nbsp;&nbsp;
                <Button icon='trash' onClick={() => setDeleteProjectConfirmOpen(true)} />
            </div>
            <ItemColors item={item} />
            {item.pictures && <div style={styles.pictures}>
                {item.pictures.map((picture, i) => (
                    <span key={i}>
                        <img style={styles.picture} src={picture} alt={picture} />
                        <br clear="all" />
                        <div style={styles.controls}>
                            {getPictureName(picture)}
                            <span>
                                <Button icon="confirm" minimal
                                    intent={picture === item.picture ? 'primary' : 'none'}
                                    onClick={() => setPicture(picture)} />
                                <Button icon="trash" minimal
                                    onClick={() => deletePictureConfirm(picture)} />
                            </span>
                        </div>
                    </span>

                ))}
            </div>}
            {item.prompt && <div><b>Prompt: </b>{item.prompt}</div>}
            {false && <Instagram imageUrl={item.picture} caption={item.caption} />}
            {showAddPicture && <div>
                <br />
                <FileUpload folder={username} item={item} />
            </div>}
            <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
                isOpen={deleteConfirmOpen} onCancel={() => setDeleteConfirmOpen(false)}
                onConfirm={() => deletePicture()}>
                <p>Delete this picture?</p>
            </Alert>
            <Alert cancelButtonText="Cancel" confirmButtonText="Delete" icon="trash" intent="warning"
                isOpen={deleteProjectConfirmOpen} onCancel={() => setDeleteProjectConfirmOpen(false)}
                onConfirm={() => deleteProject()}>
                <p>Delete this project?</p>
            </Alert>
        </div>
    }

    const ProjectInstructions = () => {
        let index = connectionIndex && connectionIndex < item.connections.length ? connectionIndex : 0;
        return <div>
            {item.connections && item.connections.map((connection, i) => (
                <div key={i}>{connection._name} <Link to={`/library/${connection._id}`}>{connection._id}</Link></div>
            ))}
            <ProjectWizard item={item} stepIndex={0} connectionIndex={index} />
        </div>
    }

    const ProjectResources = () => {
        return <div>
            {pattern && pattern.links && <Links links={pattern.links} vertical />}
        </div>
    }

    return isLoading ? <Spinner style={appstyles.spinner} /> :
        isError ? error && error.status === 404 && <center style={{ paddingTop: 100 }}>This project is yet to be created!</center> :
            item &&
            <div>
                <h2>{item.name}</h2>
                {isUpdating && <div style={{ paddingTop: 20, paddingBottom: 20 }}><ProgressBar intent='danger' /></div>}
                <div style={{ marginLeft: 4, display: 'flex', flexDirection: 'row', gap: 8 }}>
                    <Button onClick={() => setView('details')}
                        style={tabStyle(view === 'details')} intent={view === 'details' ? 'primary' : 'none'}>Details</Button>
                    <Button onClick={() => setView('instructions')}
                        style={tabStyle(view === 'instructions')} intent={view === 'instructions' ? 'primary' : 'none'}>Instructions</Button>
                    <Button onClick={() => setView('resources')}
                        style={tabStyle(view === 'resources')} intent={view === 'resources' ? 'primary' : 'none'}>Resources</Button>
                </div>
                <hr />
                {view === 'details' && <ProjectDetails />}
                {view === 'instructions' && <ProjectInstructions />}
                {view === 'resources' && <ProjectResources />}
                <ItemMetadata item={item} />
            </div>
}

const styles = {
    header: {
        display: 'flex'
    },
    topPicture: {
        marginRight: 10,
        maxWidth: 80,
        borderRadius: 5
    },
    pictures: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    picture: {
        maxHeight: 200,
        paddingRight: 10
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 5
    },
    description: {
        padding: 15
    }
};

