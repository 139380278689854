import { Icon } from '@blueprintjs/core';
import React from 'react';

const Stepper = ({ steps, currentStep, onStepPress }) => {
  return (
    <div style={styles.stepperContainer}>
      {steps.map((step, index) => (
        <div key={index} style={currentStep === index ? styles.activeStep : styles.step}
          onClick={() => onStepPress(index)}>
          <Icon icon="dot" />
        </div>
      ))}
    </div>
  );
};

const styles = {
  stepperContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8
  },
  step: {
    backgroundColor: 'white',
    color: 'lightgrey',
    justifyContent: 'center',
    alignItems: 'center',
    //margin: 4,
    cursor: 'pointer',
  },
  activeStep: {
    backgroundColor: 'azure',
    justifyContent: 'center',
    alignItems: 'center',
    //padding: 4,
    cursor: 'pointer',
    color: 'grey',
  }
};

export default Stepper;
