import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { auth } from '../firebaseConfig';

const baseUrl = "https://a.stitchso.com/app";
//const baseUrl = "https://localhost:6001/app";

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: async (headers, { getState }) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const user = auth.currentUser;
    if (user) {
      await user.getIdToken(true); // force a refresh of the token
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['project', 'progress', 'parts'],
  endpoints: (builder) => ({
    getProject: builder.query({
      query: (arg) => {
        const { pk, id } = arg;
        return {
          url: `project/${pk}/${id}`
        };
      },
      providesTags: []
    }),
    getHowToItems: builder.query({
      query: (arg) => {
        const { label } = arg;
        return {
          url: `howto/${label}`
        };
      },
      providesTags: []
    }),
    getHowToItem: builder.query({
      query: (arg) => {
        const { label, pk, id  } = arg;
        return {
          url: `howto/${label}/${pk}/${id}`
        };
      },
      providesTags: []
    }),
    getColorcharts: builder.query({
      query: () => {
        return {
          url: "design/parts/colorchart"
        };
      },
      providesTags: ['parts']
    }),
    createPart: builder.mutation({
      query: (arg) => {
        const { data } = arg;
        return {
          url: 'part',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      },
      invalidatesTags: ['parts']
    }),
    deletePart: builder.mutation({
      query: (arg) => {
        const { pk, id } = arg;
        return {
          url: `design/part/${pk}/${id}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      },
      invalidatesTags: ['parts']
    }),
    packagePattern: builder.mutation({
      query: (arg) => {
        const { label, pk, id } = arg;
        return {
          url: `package/${label}/${pk}/${id}`,
          method: 'POST'
        };
      },
      providesTags: []
    }),
  })
})

export const {
  useGetProjectQuery,
  useGetHowToItemsQuery,
  useGetHowToItemQuery,
  useGetColorchartsQuery,
  usePackagePatternMutation,
  useCreatePartMutation,
  useDeletePartMutation
} = appApi
