import { customAlphabet } from 'nanoid';

//  const nanoid = customAlphabet('23456789BCDFGHJKLMNPQRSTVWXZ', 10)
//const nanoid = customAlphabet('23456789bcdfghjkmnpqrstvwyz', 10)

export function generateId() {
  const nanoid = customAlphabet('123456789bcdfghkmnpstwx');
  return nanoid(8);
}

export function generateNumericId(len) {
  const nanoid = customAlphabet('1234567890');
  return nanoid(len);
}

export const labels = [
  "",
  "collection",
  "event",
  "motif",
  "pattern",
  "profile",
  "project",
  "shop",
  "store",
  "user",
  "quest"
];

export const crafts = [
  "knitting",
  "crochet",
  //"origami",
  //"cooking",
  //"music",
  //"art"
];

export const secondaryLabels = [
  "instructions",
  "part"
]

export function getCraftIcon(craft) {
  switch (craft) {
    case 'knitting':
      return 'waves';
    case 'crochet':
      return 'wind';
    case 'origami':
      return 'document';
    case 'cooking':
      return 'lab-test'
    case 'music':
      return 'music';
    default:
      return 'snowflake';
  }
}

export function getItemIcon(label) {
  switch (label) {
    case 'collection':
      return 'full-stacked-chart';
    case 'motif':
      return 'snowflake';
    case 'pattern':
      return 'map';
    case 'profile':
      return 'person';
    case 'project':
      return 'cube';
    case 'event':
      return 'calendar';
    default:
      return 'document';
  }
}

export function getNodeIcon(label) {
  return getItemIcon(label);
}

export function getItemProps(label) {
  switch (label) {
    default:
      return [
        { name: "name", displayName: "Name", type: "string" },
        { name: "description", displayName: "Description", type: "string" }
      ]
  }
}

export function getNodeProps() {
  return [
    { name: "label", displayName: "Label", type: "string" },
    { name: "id", displayName: "Id", type: "string" },
    { name: "pk", displayName: "Pk", type: "string" },
    { name: "name", displayName: "Name", type: "string" },
    { name: "description", displayName: "Description", type: "string" }
  ]
}

export function getItemDisplayName(item) {
  if (!!item.name)
    return item.name;
  else
    return item.label;
}

export function getType(name) {
  let type = "string";
  if (name.endsWith("_n")) type = "number";
  else if (name.endsWith("_b")) type = "bool";
  else if (name.endsWith("tags")) type = "tags";
  return type;
}

export function getDisplayValue(name, value) {
  switch (typeof (value)) {
    case "string":
      if (value.startsWith("http"))
        return (<a href={value} target="_blank" rel="noopener noreferrer">{value}</a>);
      else if (name === "instagram") {
        let url = `https://instagram.com/${value}`;
        return (<a href={url} target="_blank" rel="noopener noreferrer">{value}</a>);
      } else if (name === "color") {
        return (<div><div className="square" style={{ background: value }} /> {value}</div>);
      } else
        return value;
    case "number":
      return value;
    case "object":
      return JSON.stringify(value);
    case "boolean":
      return value ? "true" : "false"
    default:
      return value;
  }
}

export function formatDate(date) {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return date ? new Date(date).toLocaleDateString('en-US', options) : "";
}

export function formatDateTime(date) {
  const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute:'2-digit' };
  return date ? new Date(date).toLocaleTimeString('en-US', options) : "";
}

export function formatDateShort(date) {
  const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  return date ? new Date(date).toLocaleDateString('en-US', options) : "";
}

export function getMonthYear(d) {
  return d.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
}

export function maxnum(arr) {
  let max = Number.NEGATIVE_INFINITY;
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      if (arr[i][j] > max) {
        max = arr[i][j];
      }
    }
  }
  return max;
}

export function _getItemId(item) {
  return `${item.label}/${item.pk}/${item.id}`;
}

// ISO 8601 UTC standard yyyy-MM-ddTHH:mm:ss.fffffffZ
export function getCosmosDate() {
  return new Date(Date.now()).toISOString();
}

export function _tsToDateTime(_ts) {
  return new Date(_ts * 1000).toLocaleString();
}

export function isArrayOfStrings(obj) {
  if (!Array.isArray(obj)) {
    return false;
  }
  for (let i = 0; i < obj.length; i++) {
    if (typeof obj[i] !== 'string') {
      return false;
    }
  }
  return true;
}