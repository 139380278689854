import React, { useEffect, useState } from 'react';

const access_token = null;

export default function Ravelry() {
    const [accessToken] = useState(access_token);
    const [username] = useState();
    const [userData, setUserData] = useState("Not available yet");

    useEffect(() => {
        const fetchUserData = async (token) => {
            // let userUrl = "https://api.ravelry.com/current_user.json";
            let projectsUrl = `https://api.ravelry.com/projects/${username}/list.json`;
            // let deliveriesUrl = "https://api.ravelry.com/deliveries/list.json?page_size=100&page=2";
            try {
                const response = await fetch(projectsUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setUserData(data);
            } catch (error) {
                console.error('Failed to fetch user data:', error.message);
            }
        };
        if (accessToken)
            fetchUserData(accessToken);
    }, [accessToken, username]);

    return (
        <div>
            <pre>{JSON.stringify(userData, null, 2)}</pre>
        </div>
    );
};
