export default function Palette({ yarns }) {

  return yarns && (
    <div style={styles.section}>
      <b>Yarn</b>
      {yarns.map((yarn, i) => {
        return (
          <div key={i}>
            {yarn.note && <p><i>{yarn.note}</i></p>}
            {yarn.brand && <span>{yarn.brand}</span>}
            {yarn.name && <span> <b>{yarn.name}</b></span>}
            {yarn.fiber && <span> {yarn.fiber}</span>}
            {yarn.yardage && <span> ({yarn.yardage})</span>}
            {yarn.weight && <span> {yarn.weight}</span>}
            {yarn.colorways && yarn.colorways.map((colorway, index) => {
              return (
                <div style={styles.colorway} key={index}>
                  <div style={{ ...styles.color, backgroundColor: colorway.color }}></div>
                  {colorway.id} &nbsp; <b>{colorway.name}</b> {colorway.code}
                  {colorway.count && <span>&nbsp;x {colorway.count}</span>}
                  {colorway.note && <span>&nbsp;<i>({colorway.note})</i></span>}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

const styles = {
  section: {
    border: '1px solid #D3D8DE',
    padding: 8,
    marginTop: 8,
    marginBottom: 8
  },
  colorway: {
    display: 'flex',
    flexDirection: 'row',
    padding: 2
  },
  color: {
    height: 20,
    width: 20,
    borderWidth: 1,
    borderColor: 'lightgrey',
    borderStyle: 'solid',
    marginRight: 10
  }
}

