import React, { useState } from 'react';
import { Button } from '@blueprintjs/core';

export default function ProjectTOC({ steps, currentStep, onStepPress }) {
    const [isOpen, setIsOpen] = useState(false);

    const renderSteps = (steps, level = 0) => {
        return steps && steps.map((step, index) => (
            <div key={index} style={{ marginLeft: level * 10 }}>
                {level === 0 &&
                    <div style={{ ...styles.step, background: currentStep === index ? 'azure' : '' }}
                        onClick={() => onStepPress(index)}>
                        <>
                            {step.title && <div style={styles.steptitle}>{step.title}</div>}
                            {step.name && <div><i>{step.name}</i></div>}
                        </>
                    </div>}
                {false && <>
                    {step.title && <div style={styles.substeptitle}>{step.title}</div>}
                    {step.name && <div><i>{step.name}</i></div>}
                </>}
                {currentStep === index && step.steps && renderSteps(step.steps, level + 1)}
            </div>
        ));
    };

    return (
        <div style={{ marginRight: 8 }}>
            <Button small icon={isOpen ? 'chevron-left' : 'chevron-right'}
                onClick={() => setIsOpen(!isOpen)}
                style={{ float: 'right', marginLeft: 4 }} />

            {isOpen && <div style={styles.main}>
                {renderSteps(steps)}
            </div>}
        </div>
    );
};

const styles = {
    main: {
        width: 200,
        background: 'white',
        paddingRight: 16,
        marginTop: 8,
        borderRight: '1px solid #ddd',
    },
    step: {
        padding: 4,
        cursor: 'pointer',
    },
    active: {
        backgroundColor: 'azure',
    },
    steptitle: {
        fontWeight: 600 // bold
    },
    substeptitle: {
        fontWeight: 550 // semi-bold
    }
};

