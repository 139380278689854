import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faLocationDot, faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faYoutubeSquare, faInstagramSquare, faRavelry, faPinterestSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faUsers, faMailBulk } from '@fortawesome/free-solid-svg-icons';

export default function Links({ links, vertical, iconColor }) {

  
  const styles = {
    list: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    verticalList: {
    },
    block: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      padding: 4
    },
    link: {
      marginRight: 8,
      marginLeft: 4,
      color: "#000000"
    },
    txt: {
      marginRight: 8,
      marginLeft: 4
    },
    icon: {
      color: iconColor || "#147EB3",
      width: 18,
      height: 18
    }
  };

  function getIcon(type) {
    switch (type) {
      case 'youtube':
        return faYoutubeSquare;
      case 'instagram':
        return faInstagramSquare;
      case 'facebook':
        return faFacebook;
      case 'ravelry':
        return faRavelry;
      case 'pinterest':
        return faPinterestSquare;
      case 'linkedin':
        return faLinkedin;
      case 'location':
        return faLocationDot;
      case 'tutorial':
        return faGraduationCap;
      case 'mightynetworks':
        return faUsers;
      case "mailinglist":
        return faMailBulk;
      case 'website':
      default:
        return faGlobe;
    }
  }

  return links && (
    <span style={vertical ? styles.verticalList : styles.list}>
      {links && links.map((link, i) => {
        return link.url ? (
          <a key={i} href={link.url} target="_blank" rel="noreferrer">
            <span style={styles.block}>
              <FontAwesomeIcon style={styles.icon} icon={getIcon(link.type)} />
              {link.name ? <span style={styles.link}>{link.name}</span> :
                <span style={styles.link}>{link.url}</span>}
            </span>
          </a>
        ) : (
          <span key={i} style={styles.block}>
            <FontAwesomeIcon style={styles.icon} icon={getIcon(link.type)} />
            {link.name && <span style={styles.txt}>{link.name}</span>}
          </span>
        )
      })}
    </span>
  )

}