import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ControlGroup, FormGroup, HTMLSelect, InputGroup, TagInput } from '@blueprintjs/core';
import { useCreateItemMutation } from '../../store/docApi';
import { getItemProps, labels, secondaryLabels } from '../../utils';

export default function ItemCreate({ itemLabel }) {
  const navigate = useNavigate();
  const [createItem] = useCreateItemMutation();
  let defaultLabel = itemLabel ? itemLabel : "project";
  let defaultState = {};
  let itemProps = getItemProps(defaultLabel);
  itemProps.forEach(prop => {
    defaultState[prop.name] = prop.type === "tags" ? [] : "";
  });

  const [label, setLabel] = useState(defaultLabel);
  const [pk, setPk] = useState(defaultLabel);
  const [id, setId] = useState();
  const [formState, setFormState] = useState(defaultState);

  function resetItem(label) {
    setLabel(label);
    itemProps = getItemProps(label);
    itemProps.forEach(prop => {
      prop.type === "tags" ? updateFormState({ [prop.name]: [] }) :
        updateFormState({ [prop.name]: "" });
    });
    setPk(label);
  }

  function updateFormState(property) {
    setFormState(formState => ({ ...formState, ...property }));
  }

  function handleLabelChange(event) {
    resetItem(event.target.value);
  }

  function handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    updateFormState({ [name]: value });
  }

  function handleTagsChange(name, values) {
    updateFormState({ [name]: values });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    var newItem = {
      label: label
    };

    itemProps = getItemProps(label);
    itemProps.forEach(prop => {
      newItem[prop.name] = prop.type === "number" ? Number(formState[prop.name]) : formState[prop.name];
    });
    newItem["pk"] = pk;
    newItem["id"] = id;
    console.log(newItem);
    await createItem({ label: newItem.label, pk: newItem.pk, data: newItem });
    navigate(`/item/${newItem.label}/${newItem.pk}/${newItem.id}`);
  }

  itemProps = getItemProps(label);
  const allLabels = labels.concat(secondaryLabels);

  return (
    <div>
      <h3>Create</h3>
      <form style={styles.form}>
        <FormGroup label="Label" inline>
          <HTMLSelect onChange={handleLabelChange} value={label}>
            {allLabels.map((label, i) => (
              <option key={i} value={label}>{label}</option>
            ))}
          </HTMLSelect>
        </FormGroup>
        <FormGroup label="Pk" inline>
          <InputGroup name="pk" value={pk} onChange={(e)=>setPk(e.target.value)}  />
        </FormGroup>
        <FormGroup label="Id" inline>
          <ControlGroup>
            <InputGroup name="id" value={id} onChange={(e)=>setId(e.target.value)} />
          </ControlGroup>
        </FormGroup>
        {itemProps.map(prop =>
          <FormGroup key={prop.name} label={prop.displayName}>
            {prop.type === "tags" ?
              <TagInput name={prop.name} values={formState[prop.name]} onChange={(vals) => handleTagsChange(prop.name, vals)} />
              : prop.type === "select" ?
                <div className="bp3-html-select">
                  <select name={prop.name} onChange={handleChange} value={formState[prop.name]}>
                    {prop.options.map(option =>
                      <option key={option} value={option}>{option}</option>
                    )}
                  </select>
                </div>
                :
                <InputGroup name={prop.name} onChange={handleChange} />
            }
          </FormGroup>
        )}
        <Button onClick={handleSubmit} intent="primary">Submit</Button>
      </form>
    </div>
  );
}

const styles = {
  form: {
    paddingLeft: 20
  }
}
