import { useEffect, useState } from 'react';
import { Button, Callout, FormGroup, HTMLSelect, InputGroup, NumericInput, Section, TextArea } from '@blueprintjs/core';
import { useGetItemsQuery } from '../../store/docApi';
import { useProductCreateMutation } from '../../store/stripeApi';

export default function ProductCreate({ item }) {
  const { data: shops, isLoading } = useGetItemsQuery({ label: "shop", userContentOnly: true })
  const [productCreate, { isError }] = useProductCreateMutation();

  const [name, setName] = useState(item.name);
  const [description, setDescription] = useState(item.description);
  const [price, setPrice] = useState(0);
  const [accountId, setAccountId] = useState();
  const [newProductId, setNewProductId] = useState();

  useEffect(() => {
    if (shops && shops.length > 0) {
      for (let i = 0; i < shops.length; i++) {
        if (shops[i].stripeAccountId) {
          setAccountId(shops[i].stripeAccountId);
          break;
        }
      }
    }
  }, [shops, accountId]);

  async function createProduct() {
    try {
      let response = await productCreate({
        props: {
          accountId: accountId, name: item.name, description: item.description, price: parseInt(price*100), imageUrls: item.pictures,
          pk: item.pk, id: item.id, label: item.label
        }
      }).unwrap();

      if (response.productId) {
        setNewProductId(response.productId);
      }
    } catch (error) {
      console.log("Failed to create a product");
    }
  }

  function handlePriceChange(num, str) {
    setPrice(str)
  };

  function handleShopChange(event) {
    const selectedValue = event.target.value;
    console.log(selectedValue)
    setAccountId(selectedValue);
  };

  return isLoading ? <div>Loading</div> : accountId ? (
    <Section style={styles.section}>
      <FormGroup label="Shop">
        <HTMLSelect onChange={handleShopChange} defaultValue={accountId}>
          {shops && shops.map((shop, i) => (
            shop.stripeAccountId && <option key={i} value={shop.stripeAccountId}>{shop.name}</option>
          ))}
        </HTMLSelect>
      </FormGroup>
      <FormGroup label="Name">
        <InputGroup placeholder="Name" value={name} onChange={(event) => setName(event.target.value)} />
      </FormGroup>
      <FormGroup label="Description">
        <TextArea placeholder="Description" value={description} onChange={(event) => setDescription(event.target.value)} autoResize />
      </FormGroup>
      <FormGroup label="Price($)">
        <NumericInput placeholder="Price" value={price} onValueChange={handlePriceChange} min={0} max={100} minorStepSize={0.01} allowNumericCharactersOnly={true} />
      </FormGroup>
      <Button onClick={createProduct} >Create product</Button>
      {newProductId && <Callout intent="success">Product created {newProductId}</Callout>}
      {isError && <Callout intent="danger">Error creating a product</Callout>}
    </Section>
  ) : <Callout intent="warning" style={styles.callout}>Please setup a shop to publish to. {accountId} </Callout>
};

const styles = {
  section: {
    padding: 20,
    marginTop: 20
  },
  callout: {
    marginTop: 10
  }
}
