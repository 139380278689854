import { Button, ControlGroup, Divider, FileInput, Label, Menu, MenuDivider, MenuItem, NumericInput, Popover, Position, Radio, RadioGroup } from "@blueprintjs/core";
import React, { useCallback, useRef, useEffect, useState } from "react";


const defaultColors = ["#ffffff", "#a9a9a9", '#808080', '#696969'];

export default function ColorChartCanvas({ colors: initialColors, data: initialData, onSaveProperty }) {
  const canvasRef = useRef(null);
  const isDrawing = useRef(false); // Track whether the user is currently drawing
  const isSelecting = useRef(false); // Track whether the user is selecting an area
  const [colors, setColors] = useState(initialColors ? initialColors : defaultColors);
  const [data, setData] = useState(initialData ? initialData : [[0]]);
  const [rows, setRows] = useState(initialData ? initialData.length : 0);
  const [cols, setCols] = useState(initialData && initialData.length ? initialData[0].length : 0);
  const [cellSize, setCellSize] = useState(16); // Default smaller cell size
  const [yscale, setYscale] = useState(1); // Scale to a square by default, set to < 1 for wider cells
  const [currentColor, setCurrentColor] = useState(0); // Index of the selected color
  const [currentRow, setCurrentRow] = useState(-1); // Currently highlighted row
  const [thickLineEveryX, setThickLineEveryX] = useState(5); // Thicker vertical line every X cells
  const [thickLineEveryY, setThickLineEveryY] = useState(5); // Thicker horizontal line every Y cells
  const [clipboard, setClipboard] = useState(null); // Clipboard to store copied data
  const [selection, setSelection] = useState(null); // Current selection rectangle
  const [showFileImport, setShowFileImport] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSelect, setIsSelect] = useState(false);

  const margin = 24; // Space for row and column numbers

  // Copy the selected area or the whole chart
  const handleCopy = () => {
    if (selection) {
      const { startRow, startCol, endRow, endCol } = selection;
      const copiedData = data
        .slice(startRow, endRow + 1)
        .map((row) => row.slice(startCol, endCol + 1));
      setClipboard(copiedData);
      console.log("Selected area copied!");
    } else {
      setClipboard(data);
      console.log("Entire chart copied");
    }
  };

  // Cut the selected area: copy it to the clipboard and clear it from the chart
  const handleCut = () => {
    if (selection) {
      const { startRow, startCol, endRow, endCol } = selection;
      const copiedData = data
        .slice(startRow, endRow + 1)
        .map((row) => row.slice(startCol, endCol + 1));
      setClipboard(copiedData);

      // Clear the selection area in the chart
      setData((prevData) =>
        prevData.map((row, rowIndex) =>
          row.map((cell, colIndex) => {
            if (
              rowIndex >= startRow &&
              rowIndex <= endRow &&
              colIndex >= startCol &&
              colIndex <= endCol
            ) {
              return 0; // Clear the cell
            }
            return cell;
          })
        )
      );
      console.log("Selected area cut");
    } else {
      console.log("No selection to cut");
    }
  };

  // Paste the clipboard data into the chart
  const handlePaste = () => {
    if (!clipboard) {
      console.log("No chart copied to paste");
      return;
    }
    console.log("Pasting");

    // Determine where to paste: top-left of the selection or (0, 0)
    const pasteRow = selection ? selection.startRow : 0;
    const pasteCol = selection ? selection.startCol : 0;

    setData((prevData) => {
      const newData = prevData.map((row, rowIndex) =>
        row.map((cell, colIndex) => {
          // Determine where to paste the clipboard content
          const clipboardRow = rowIndex - pasteRow;
          const clipboardCol = colIndex - pasteCol;

          // Check if the current cell falls within the clipboard boundaries
          if (
            clipboardRow >= 0 &&
            clipboardRow < clipboard.length &&
            clipboardCol >= 0 &&
            clipboardCol < clipboard[0].length
          ) {
            return clipboard[clipboardRow][clipboardCol];
          }
          return cell; // Keep the existing cell value if outside the clipboard area
        })
      );
      console.log(`Chart pasted at row ${pasteRow + 1}, column ${pasteCol + 1}`);
      return newData;
    });
  };

  function handleSetRows(newRows) {
    updateGrid('bottom', newRows > rows);
  }

  function handleSetCols(newCols) {
    updateGrid('right', newCols > cols);
  }

  function updateGrid(loc, add) {
    let newdata = data.map(innerArray => innerArray.slice());
    let count = 1;
    switch (loc) {
      case 'top':
        if (add) newdata.unshift(Array(newdata[0].length).fill(0));
        else newdata.splice(0, count);
        break;
      case 'bottom':
        if (add) newdata.push(Array(newdata[0].length).fill(0));
        else newdata.splice(newdata.length - count, count);
        break;
      case 'left':
        for (let i = 0; i < newdata.length; i++) {
          if (add) newdata[i] = Array(count).fill(0).concat(newdata[i]);
          else newdata[i] = newdata[i].slice(count);
        }
        break;
      case 'right':
        for (let i = 0; i < newdata.length; i++) {
          if (add) newdata[i] = newdata[i].concat(Array(count).fill(0));
          else newdata[i] = newdata[i].slice(0, newdata[i].length - count);
        }
        break;
      default:
        return;
    }
    setData(newdata);
    setRows(newdata ? newdata.length : 0);
    setCols(newdata.length > 0 && newdata[0] ? newdata[0].length : 0);
  }

  // Draw the chart on the canvas
  const drawChart = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");

    // Clear the canvas
    canvas.width = cols * cellSize + margin;
    canvas.height = rows * cellSize * yscale + margin;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the cells
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        const cellValue = data[row]?.[col] ?? 0;
        ctx.fillStyle = colors[cellValue];
        ctx.fillRect(col * cellSize, row * cellSize * yscale, cellSize, cellSize);
      }
    }

    // Dim all rows except the current row
    /*
    ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
    for (let row = 0; row < rows; row++) {
      if (row !== currentRow) {
        ctx.fillRect(0, row * cellSize * yscale, cols * cellSize, cellSize);
      }
    }
    */
    // Draw a yellow border around the current row
    if (currentRow >= 0 && !isEdit) {
      ctx.strokeStyle = "yellow";
      ctx.lineWidth = 3;
      ctx.strokeRect(0, currentRow * cellSize, cols * cellSize, cellSize + 1);
    }

    // Draw the grid lines
    ctx.lineWidth = 0.3; // Thin lines for regular grid
    ctx.strokeStyle = "black";

    // Horizontal lines
    for (let y = 0; y <= rows; y++) {
      ctx.beginPath();
      const yPos = Math.round(y * cellSize * yscale) + 0.5; // Align to pixel boundary
      ctx.moveTo(0, yPos);
      ctx.lineTo(cols * cellSize, yPos);
      ctx.stroke();
    }

    // Vertical lines
    for (let x = 0; x <= cols; x++) {
      ctx.beginPath();
      const xPos = Math.round(x * cellSize) + 0.5; // Align to pixel boundary
      ctx.moveTo(xPos, 0);
      ctx.lineTo(xPos, rows * cellSize * yscale);
      ctx.stroke();
    }

    // Thicker grid lines
    ctx.lineWidth = 1; // Thicker lines
    ctx.strokeStyle = "black";

    // Horizontal thick lines (from bottom to top)
    if (thickLineEveryY > 0 && thickLineEveryY < rows) {
      for (let y = rows - thickLineEveryY; y >= 0; y -= thickLineEveryY) {
        ctx.beginPath();
        const yPos = Math.round(y * cellSize * yscale) + 0.5; // Align to pixel boundary
        ctx.moveTo(0, yPos);
        ctx.lineTo(cols * cellSize, yPos);
        ctx.stroke();
      }
    }

    // Vertical thick lines (from right to left)
    if (thickLineEveryX > 0 && thickLineEveryX < cols) {
      for (let x = cols - thickLineEveryX; x >= 0; x -= thickLineEveryX) {
        ctx.beginPath();
        const xPos = Math.round(x * cellSize) + 0.5; // Align to pixel boundary
        ctx.moveTo(xPos, 0);
        ctx.lineTo(xPos, rows * cellSize * yscale);
        ctx.stroke();
      }
    }
    // Draw the selection rectangle
    if (selection) {
      const { startRow, startCol, endRow, endCol } = selection;
      ctx.strokeStyle = "blue";
      ctx.lineWidth = 2;
      ctx.strokeRect(
        startCol * cellSize,
        startRow * cellSize * yscale,
        (endCol - startCol + 1) * cellSize,
        (endRow - startRow + 1) * cellSize * yscale
      );
    }

    // Draw row and column numbers if the cells are large enough
    if (cellSize >= 16) {
      // Fill background for row numbers
      ctx.fillStyle = "white";
      ctx.fillRect(cols * cellSize, 0, margin, rows * cellSize * yscale);

      // Fill background for column numbers
      ctx.fillRect(0, rows * cellSize * yscale, cols * cellSize + margin, margin);

      // Draw reversed row numbers on the right
      ctx.font = "10px Verdana";
      ctx.textAlign = "left";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "black";
      for (let row = 0; row < rows; row++) {
        ctx.fillText(rows - row, cols * cellSize + 5, row * cellSize * yscale + cellSize / 2);
      }

      // Draw reversed column numbers at the bottom
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      for (let col = 0; col < cols; col++) {
        ctx.fillText(cols - col, col * cellSize + cellSize / 2, rows * cellSize * yscale + 5);
      }
    }
  }, [cols, rows, cellSize, yscale, data, colors, thickLineEveryX, thickLineEveryY, currentRow, selection, isEdit]);

  // Update the canvas whenever the data, colors, grid size, or line settings change
  useEffect(() => {
    drawChart();
  }, [drawChart]);

  // Update a single cell with the current color
  const updateCell = (x, y) => {
    const col = Math.floor(x / cellSize);
    const row = Math.floor(y / cellSize / yscale);

    if (col >= 0 && col < cols && row >= 0 && row < rows) {
      setData((prevData) => {
        const newData = [...prevData.map((r) => [...r])];
        newData[row][col] = currentColor;
        return newData;
      });
    }
  };

  function handleStart(event) {
    if (isSelect) {
      handleStartSelect(event);
    } else if (isEdit) {
      handleStartDraw(event);
    } else {
      // select current row
      const rect = canvasRef.current.getBoundingClientRect();
      //const x = event.clientX;
      const y = event.clientY || event.touches[0].clientY;
      const row = Math.floor((y - rect.top) / cellSize / yscale);
      setCurrentRow(row);
    }
  }

  function handleMove(event) {
    if (isSelect) {
      handleMoveSelect(event);
    } else if (isEdit) {
      handleMoveDraw(event);
    }
  }

  function handleEnd() {
    if (isSelect) {
      handleEndSelect();
    } else if (isEdit) {
      handleEndDraw();
    }
  }

  // DRAWING FUNCTIONS
  // Handle mouse or touch start
  const handleStartDraw = (event) => {
    isDrawing.current = true;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX || event.touches[0].clientX;
    const y = event.clientY || event.touches[0].clientY;
    updateCell(x - rect.left, y - rect.top);
  };

  // Handle mouse or touch move
  const handleMoveDraw = (event) => {
    if (!isDrawing.current) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX || event.touches[0].clientX;
    const y = event.clientY || event.touches[0].clientY;
    updateCell(x - rect.left, y - rect.top);
  };

  // Handle mouse or touch end
  const handleEndDraw = () => {
    isDrawing.current = false;
  };

  // SELECTION FUNCTIONS
  // Handle mouse down for selection
  const handleStartSelect = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = (event.clientX || event.touches[0].clientX) - rect.left;
    const y = (event.clientY || event.touches[0].clientY) - rect.top;

    const startCol = Math.floor(x / cellSize);
    const startRow = Math.floor(y / cellSize / yscale);

    setSelection({ startRow, startCol, endRow: startRow, endCol: startCol });
    isSelecting.current = true;
  };

  const handleMoveSelect = (event) => {
    if (!isSelecting.current) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = (event.clientX || event.touches[0].clientX) - rect.left;
    const y = (event.clientY || event.touches[0].clientY) - rect.top;

    const endCol = Math.floor(x / cellSize);
    const endRow = Math.floor(y / cellSize / yscale);

    setSelection((prevSelection) => ({
      ...prevSelection,
      endRow: Math.max(0, Math.min(rows - 1, endRow)),
      endCol: Math.max(0, Math.min(cols - 1, endCol)),
    }));
  };

  const handleEndSelect = () => {
    isSelecting.current = false;
  };

  // Handle row navigation
  const handlePrevRow = () => {
    setCurrentRow((prevRow) => (prevRow + 1) % rows);
  };

  const handleNextRow = () => {
    setCurrentRow((prevRow) => (prevRow - 1 + rows) % rows);
  };

  // Export the data as a JSON file
  const handleSaveToFile = () => {
    const dataStr = JSON.stringify(data);
    const blob = new Blob([dataStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    // Create a temporary download link
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.json";
    link.click();
    URL.revokeObjectURL(url);
  };

  // Export the canvas as an image
  const handleExportImage = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "chart.png";
    link.click();
  };

  // Load a saved data from a JSON file
  const handleLoadData = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const loadedData = JSON.parse(e.target.result);
        setData(loadedData);
        setRows(loadedData.length);
        setCols(loadedData[0].length);
      };
      reader.readAsText(file);
      setShowFileImport(false);
    }
  };

  // Clear the grid
  const handleClear = () => {
    setData(Array.from({ length: rows }, () => Array.from({ length: cols }, () => 0)));
  };

  // Reset the grid
  const handleReset = () => {
    setData(initialData ? initialData : [[0]]);
    setRows(initialData ? initialData.length : 0);
    setCols(initialData.length > 0 && initialData[0] ? initialData[0].length : 0);
  }

  const isDataChanged = JSON.stringify(data) !== JSON.stringify(initialData);
  const isColorsChanged = JSON.stringify(colors) !== JSON.stringify(initialColors);

  const moreMenu = (
    <Menu>
      <MenuItem icon='select' onClick={() => setIsSelect(true)} text="Selection Mode" />
      <MenuItem icon="undo" onClick={handleReset} text="Undo all" />
      <MenuItem icon="eraser" onClick={handleClear} text="Clear" />
      <MenuDivider />
      <MenuItem icon='media' onClick={handleExportImage} text="Export as image" />
      <MenuItem icon='download' onClick={handleSaveToFile} text="Export to file" />
      <MenuItem icon="upload" onClick={() => setShowFileImport(true)} text="Import from file" />
    </Menu>
  );

  return (
    <div style={styles.container}>
      {isEdit && !isSelect && <div style={{ ...styles.controlsHorizontal, paddingBottom: 16, paddingTop: 16 }}>
        {colors.map((color, index) => (
          <div key={index} style={styles.colorWrapper}>
            <input type="color" value={color} onChange={(e) => setColors((prevColors) => {
              const newColors = [...prevColors];
              newColors[index] = e.target.value;
              return newColors;
            })} />
            <div>{color}</div>
            <Button onClick={() => setCurrentColor(index)}
              style={{ backgroundColor: color, border: currentColor === index ? "3px solid " : "1px solid gray" }} />
          </div>
        ))}
        {isColorsChanged && <Button icon='floppy-disk' onClick={() => onSaveProperty("colors", colors)} />}
      </div>
      }

      <div style={{ ...styles.controlsHorizontal }}>
        {<Button icon="settings" onClick={() => setShowSettings(!showSettings)} active={showSettings} />}
        {isEdit && <Popover content={moreMenu} position={Position.BOTTOM}>
          <Button icon="more" />
        </Popover>}
        {!isEdit && <Button icon="edit" onClick={() => setIsEdit(!isEdit)} text="Edit" />}
        {isEdit && <Button icon="floppy-disk" text="Save" disabled={!isDataChanged}
          onClick={() => { onSaveProperty("data", data); setIsEdit(false) }} />}
        {isEdit && <Button icon="cross" onClick={() => { handleReset(); setIsEdit(false); setIsSelect(false) }} text="Cancel" />}
      </div>

      {showSettings && <div style={styles.controlsHorizontal}>
        <Label>
          Vertical lines:
          <NumericInput value={thickLineEveryX} min="0" style={styles.numeric}
            onValueChange={setThickLineEveryX} />
        </Label>
        <Label>
          Horizontal lines:
          <NumericInput min="0" style={styles.numeric} value={thickLineEveryY}
            onValueChange={setThickLineEveryY} />
        </Label>
        <Label>Cell Size:
          <NumericInput value={cellSize} min="5" style={styles.numeric}
            onValueChange={setCellSize} />
        </Label>
          <RadioGroup inline label="Cell shape:" onChange={(e) => setYscale(Number(e.target.value))} selectedValue={yscale}>
            <Radio label="Square" value={1} />
            <Radio label="Rectangle (4:5 ratio)" value={0.8} />
            <Radio label="Rectangle (2:3 ratio)" value={0.67} />
          </RadioGroup>
      </div>}


      {showFileImport && <div>
        <Label>Load data from file
          <ControlGroup>
            <FileInput type="file" accept="application/json" onChange={handleLoadData} />
            <Button icon="cross" onClick={() => setShowFileImport(false)} />
          </ControlGroup>
        </Label>
      </div>}

      {isEdit && <div style={styles.controlsHorizontal}>
        <Label>Rows:
          <NumericInput value={rows} min="1" max="100" onValueChange={handleSetRows} style={styles.numeric} />
        </Label>
        <Label>Columns:
          <NumericInput value={cols} min="1" style={styles.numeric} onValueChange={handleSetCols} />
        </Label>
      </div>}

      {isSelect && <div style={styles.controlsHorizontal}>
        <Button icon='duplicate' onClick={handleCopy} disabled={selection === null} />
        <Button icon='cut' onClick={handleCut} disabled={selection === null} />
        <Button icon='clipboard-file' onClick={handlePaste} disabled={clipboard === null} />
        <Button icon='disable' onClick={() => { setSelection(null); setIsSelect(false) }} />
      </div>}

      {isEdit && <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 4 }}>
        <Button icon="add-column-left" onClick={() => updateGrid('left', true)} />
        <Button icon="remove-column-left" onClick={() => updateGrid('left', false)} />
        <Divider />
        <Button icon="add-row-top" onClick={() => updateGrid('top', true)} />
        <Button icon="remove-row-top" onClick={() => updateGrid('top', false)} />
        <Divider />
        <Button icon="add-row-bottom" onClick={() => updateGrid('bottom', true)} />
        <Button icon="remove-row-bottom" onClick={() => updateGrid('bottom', false)} />
        <Divider />
        <Button icon="add-column-right" onClick={() => updateGrid('right', true)} />
        <Button icon="remove-column-right" onClick={() => updateGrid('right', false)} />
      </div>}

      <div style={styles.canvasContainer}>
        <canvas ref={canvasRef} style={isEdit ? styles.canvasDrawMode : {}}
          onMouseDown={handleStart} onMouseMove={handleMove} onMouseUp={handleEnd} onMouseLeave={handleEnd}
          onTouchStart={handleStart} onTouchMove={handleMove} onTouchEnd={handleEnd} />
      </div>

      {!isEdit && <div style={styles.controlsHorizontal}>
        <Button icon="arrow-down" onClick={handlePrevRow} >Previous</Button>
        <Button icon="arrow-up" onClick={handleNextRow} >Next</Button>
      </div>}

    </div>
  );
};

const styles = {
  numeric: {
    width: 50,
  },
  controlsHorizontal: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,
  },
  container: {
    marginTop: '20px',
  },
  colorWrapper: {
    textAlign: "center",
    fontSize: '10px'
  },
  canvasContainer: {
    overflowX: "auto",
    maxWidth: "100%",
    paddingTop: 8,
    borderStyle: "solid",
    borderWidth: "1px 0px 1px 0px",
    borderColor: "lightgray",
  },
  canvasDrawMode: {
    touchAction: "none", // Prevent touch scrolling
  },
};
