import React from 'react';

// This only supports bold ** and italic _ text that is not nested
// For example, **bold _italic_ bold** will not work
const Markdown = ({ text }) => {
  // Function to parse markdown text and return React elements
  const parseMarkdown = (input) => {
    const lines = input.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {index > 0 && <br />} {/* Add <br /> tag for new lines */}
        {parseLine(line)}
      </React.Fragment>
    ));
    return lines;
  };

  // Function to parse individual line (supports bold, italic)
  const parseLine = (line) => {
    // Replace **text** with bold text
    let parts = [];
    let splitByBold = line.split('**');
    splitByBold.forEach((part, index) => {
      if (index % 2 === 1) {
        parts.push(<strong key={index}>{part}</strong>); // Bold text
      } else {
        parts.push(part);
      }
    });

    // Replace _text_ with italic text
    let result = [];
    parts.forEach((part, i) => {
      if (typeof part === 'string') {
        let splitByItalic = part.split('_');
        splitByItalic.forEach((italicPart, idx) => {
          if (idx % 2 === 1) {
            result.push(<em key={`${i}_${idx}`}>{italicPart}</em>); // Italic text
          } else {
            result.push(italicPart);
          }
        });
      } else {
        result.push(part);
      }
    });

    return result;
  };

  return <div>{parseMarkdown(text)}</div>;
};

export default Markdown;
