import { useParams, useNavigate, Link } from 'react-router-dom'
import { useGetConnectedAllQuery } from '../../store/docApi'
import { useGetHowToItemQuery } from '../../store/appApi'
import { Button } from '@blueprintjs/core'
import { appstyles } from '../../styles/appstyles'
import ItemMetadata from '../Widgets/ItemMetadata'
import Picture from '../Widgets/Picture'
import Sizing from '../Widgets/Sizing'

export default function HowtoPatternDetails() {
    let params = useParams();
    const navigate = useNavigate();
    let id = params.id;
    let pk = params.pk;

    const { data: item, isError, error: patternError, isLoading } = useGetHowToItemQuery({ label: "pattern", pk: pk, id: id });
    let _id = `pattern/${pk}/${id}`;
    const { data: projects } = useGetConnectedAllQuery({ _id: _id, container: "project" });

    if (isError) console.log(_id, patternError);
    if (!item && isLoading) return <div>Fetching pattern data...</div>;

    return item && <div stylele={appstyles.screen}>
        <div>
            <h3>{item.name}</h3>
            @{item.pk}
            <Picture picture={item.picture} pictures={item.pictures} />
            {item.description && <p style={appstyles.text}>{item.description} </p>}
            {item.sizing && <Sizing sizing={item.sizing} />}
            <div>
                {projects && <h4>My projects</h4>}
                <ul>
                    {projects && projects.map((project, i) => (
                        <li key={i}>
                            <Link to={`/project/${project.pk}/${project.id}`}>
                                {project.name}
                            </Link>
                        </li>
                    ))}
                </ul>
                <Button onClick={() => navigate(`/project/create/pattern/${item.pk}/${item.id}`)} >
                    New project
                </Button>
            </div>
        </div>
        <ItemMetadata item={item} />
    </div>
}
